var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"user-list"}},[_c('v-card',{attrs:{"rounded":"xl","color":_vm.$vuetify.breakpoint.xsOnly ? 'white' : 'thead-color',"disabled":_vm.loading}},[_c('v-card-title',{staticClass:"text-h6",class:_vm.$vuetify.breakpoint.xsOnly ? 'thead-color' : 'form-color'},[_c('v-icon',{attrs:{"left":""}},[_vm._v(" "+_vm._s(_vm.icons.mdiTrayFull)+" ")]),_vm._v(" Ekstre ")],1),_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":"12"}},[_c('v-text-field',{staticClass:"rounded-0",attrs:{"label":"Arama Terimi","placeholder":"İsteğe Bağlı","persistent-placeholder":"","rounded":"","filled":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)],1),_c('v-data-table',{attrs:{"headers":_vm.tableColumns,"sort-by":"head.OrderNumber","sort-desc":true,"search":_vm.search,"loading":_vm.loading,"items":_vm.tableItems},scopedSlots:_vm._u([(_vm.sumBalance > 0)?{key:"footer",fn:function(){return [_c('v-card',{attrs:{"color":"primary","elevation":"1","rounded":"t-lg b-0"}},[_c('v-card-title',{staticClass:"text-sm"},[_c('span',{staticClass:"white--text"},[_vm._v("Bakiye Toplamı ")]),_vm._v(" "),_c('v-spacer'),_c('span',{staticClass:"white--text"},[_vm._v(_vm._s(_vm._f("currency")(_vm.sumBalance)))])],1)],1)]},proxy:true}:null,{key:"item.RefNumber",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{attrs:{"label":"","small":""}},[_vm._v(" "+_vm._s(item.RefNumber)+" ")])]}},{key:"item.Loc_Debit",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"text-caption error--text"},[_vm._v(" "+_vm._s(_vm._f("currency")(item.Loc_Debit))+" ")])]}},{key:"item.Loc_Credit",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"text-caption success--text"},[_vm._v(" "+_vm._s(_vm._f("currency")(item.Loc_Credit))+" ")])]}},{key:"item.Loc_Balance",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"text-caption primary--text"},[_vm._v(" "+_vm._s(_vm._f("currency")(item.Loc_Balance))+" ")])]}}],null,true)})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }